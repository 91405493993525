import clsx from 'clsx';
import { AnimatePresence, MotionProps, motion } from 'framer-motion';
import React, { HTMLAttributes, ReactNode, useState } from 'react';

import styles from './Accordion.module.scss';

type Props = Omit<HTMLAttributes<HTMLDivElement>, 'title'> & {
  title: string | ReactNode;
  pt?: {
    header?: HTMLAttributes<HTMLButtonElement>;
    title?: HTMLAttributes<HTMLDivElement>;
    chevron?: HTMLAttributes<HTMLDivElement>;
    content?: HTMLAttributes<HTMLDivElement> & MotionProps;
  };
};

export const Accordion = ({ className, title, children, pt, ...props }: Props) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const onButtonClick = () => {
    setIsActive(!isActive);
  };

  return (
    <div {...props} className={clsx(styles.wrapper, { [styles.active]: isActive }, className)}>
      <button
        {...pt?.header}
        className={clsx(styles.header, { [styles.active]: isActive }, pt?.header?.className)}
        onClick={onButtonClick}
      >
        {typeof title === 'string' ? (
          <span {...pt?.title} className={clsx(styles.title, pt?.title?.className)}>
            {title}
          </span>
        ) : (
          title
        )}
        <span {...pt?.chevron} className={clsx(styles.chevron, pt?.chevron?.className)} />
      </button>
      <AnimatePresence initial={false}>
        {isActive && (
          <motion.div
            {...pt?.content}
            className={clsx(styles.content, pt?.content?.className)}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { height: 'auto', opacity: 1 },
              collapsed: { height: 0, opacity: 0 },
            }}
            transition={{ duration: 0.2, ease: 'easeIn' }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
