import clsx from 'clsx';
import { FC, HTMLAttributes, PropsWithChildren } from 'react';

import styles from './Container.module.scss';

interface ContainerProps {
  variant?:
    | 'sm'
    | 'md'
    | 'xl'
    | 'full'
    | 'checkout'
    | 'informationPages'
    | 'paylater'
    | 'footer'
    | 'promotions'
    | 'newUiSystem';
}

const Container: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>> & ContainerProps> = ({
  variant = 'xl',
  children,
  className,
  ...rest
}) => {
  return (
    <div className={clsx(className, styles.container, variant && styles[variant])} {...rest}>
      {children}
    </div>
  );
};

export default Container;
