import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

import { Image } from '@/components/image';

import { formatCurrency } from '@/helpers/number/format-currency';
import { countBonus } from '@/helpers/string/case-choise';
import { getImageFormatUrl } from '@/helpers/string/getImageFormatUrl';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useTypedSelector } from '@/hooks/useTypedSelector';

import {
  addProduct,
  removeProduct,
  sumCount,
  totalCount,
} from '@/store/slices/checkout-product/checkout-product.slice';

import { IProductNew } from '@/types/new/products';

import { ROUTES } from '@/constants/routes.constant';

import styles from './AdditionalProductCard.module.scss';
import * as Assets from './assets';

type Props = {
  product: IProductNew;
};

export const AdditionalProductCard = ({ product }: Props) => {
  const { name, price, media, slug, promocode_available } = product;
  const { cashback } = useTypedSelector((state) => state.user);
  const returnBonusCount = countBonus(cashback, price);
  const dispatch = useAppDispatch();
  const { salePercent, products } = useTypedSelector((state) => state.checkoutProducts);

  const inCart = products.length
    ? Boolean(products.find((el) => el?.product?.id === product.id))
    : false;

  const onAddHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!inCart) {
      const localStorageProduct = {
        product,
        amount: 1,
      };

      dispatch(addProduct(localStorageProduct));
    } else {
      dispatch(removeProduct(product.id));
    }

    dispatch(sumCount());
    dispatch(totalCount());
  };

  return (
    <div className={styles.wrapper}>
      <Link className={styles.imageWrapper} href={ROUTES.PRODUCT(slug)}>
        <Image
          className={styles.image}
          src={getImageFormatUrl(media[0], 'small')}
          fill
          alt={name}
        />
        <span className={styles.bonuses}>
          +{returnBonusCount}
          <Image src={Assets.AzCoinIcon} alt="Иконка АзалияКоин" width={12} height={12} />
        </span>
      </Link>
      <Link className={styles.body} href={ROUTES.PRODUCT(slug)}>
        <div className={styles.name}>{name}</div>
        <div className={styles.price}>
          <span>
            {formatCurrency(
              promocode_available ? +(price - (price * salePercent) / 100).toFixed(0) : price
            )}
          </span>{' '}
          {!!salePercent && (
            <span>
              {formatCurrency(price)}
              <Assets.PriceLineIcon />
            </span>
          )}
        </div>
      </Link>
      <button className={clsx(styles.addButton, inCart && styles.inCart)} onClick={onAddHandler}>
        {inCart ? 'Убрать' : 'Добавить'}
      </button>
    </div>
  );
};
