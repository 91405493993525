import clsx from 'clsx';
import Link from 'next/link';
import { FC, HTMLAttributes } from 'react';

import styles from './Breadcrumbs.module.scss';

type IBreadCrumbs = {
  items: { name: string; href?: string }[];
  newDesign?: boolean;
};

const BreadCrumbs: FC<HTMLAttributes<HTMLUListElement> & IBreadCrumbs> = ({
  items,
  className,
  newDesign,
  ...props
}) => {
  return (
    <ul
      className={clsx(styles.breadcrumbs, className)}
      itemScope
      itemType="https://schema.org/BreadcrumbList"
      {...props}
    >
      {items.map((item, index) =>
        item.href ? (
          <li
            key={item.name}
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
            className={clsx(
              newDesign ? styles.item_new : styles.item,
              index === items.length - 1 && styles.active
            )}
          >
            <Link href={item.href} itemProp="item">
              <span itemProp="name">{item.name}</span>
              <meta itemProp="position" content={`${index + 1}`} />
            </Link>
          </li>
        ) : (
          <li
            key={item.name}
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
            className={clsx(
              newDesign ? styles.item_new : styles.item,
              index === items.length - 1 && styles.active
            )}
          >
            <div itemProp="item">
              <span itemProp="name">{item.name}</span>
              <meta itemProp="position" content={`${index + 1}`} />
            </div>
          </li>
        )
      )}
    </ul>
  );
};

export default BreadCrumbs;
